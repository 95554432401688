import React from "react"
import HomeLayout from "../layouts/HomeLayout"
import { graphql } from "gatsby"
import SEO from "../components/Seo"
import SectionTitle from "../components/SectionTitle"
import Lightbox from "../utils/Lightbox"

const GaleriaPage = ({data}) => (
  <>
    <SEO title="Galeria zdjęć - Pogrzeb, wystrój grobu, winda pogrzebowa" />
    <HomeLayout data={data}>
        <div id="galeria" className="galeria-page page-content">
            <div className="container">
                <div className="content">
                    <SectionTitle title="Galeria" bottom={false} />
                    <p className="text-center">
                        Szanowni Państwo, poniżej znajduje się kilkanaście zdjęć prezentujących<br/>
                        jakość i profesjonalizm wykonywanych i oferowanych przez nas usług,<br/>
                        a także flotę naszych pojazdów, wyposażenia i posiadanego sprzętu.
                    </p>
                </div>
            </div>
            <div className="container">
                <div className="content text-center section-content">
                    <h2>Dom pogrzebowy</h2>
                    <p className="text-center">
                        Dom pogrzebowy będący chlubą naszej firmy, powstał w 2014 roku. Wybudowaliśmy ten obiekt celem zaoferowania Państwu usług światowej klasy (profesjonalne chłodnie, pomieszczenia toalety pośmierntej i balsamacji)
                        oraz miejsca, w którym z należytą godnością i powagą będą Państwo mogli pożegnać się ze swoimi bliskimi, odmówić różaniec czy innego rodzaju obrzędy religijne lub świeckie.<br/><br/> 
                    </p>
                </div>
            </div>
            <div className="container">
                <div className="galeria__section__content">
                  <Lightbox photos={data.gallery_dom.edges} />
                </div>
            </div>
            <div className="container">
                <div className="content text-center section-content">
                    <h2>Flota pogrzebowa</h2>
                    <p className="text-center">
                        W swojej ofercie posiadamy karawany koloru czarnego oraz srebrnego marki Mercedes Vito oraz Fiat Scudo.<br/><br/>
                    </p>
                </div>
            </div>
            <div className="container">
                <div className="galeria__section__content">
                  <Lightbox photos={data.gallery_cars.edges} />
                </div>
            </div>
            <div className="container">
                <div className="content text-center section-content">
                    <h2>Ceremonia pogrzebowa</h2>
                    <p className="text-center">
                        Staramy się, aby jakośc oferowanych przez nas usług była na światowym poziomie, dlatego też jako pierwsi wprowadziliśmy na Ostrowskim rynku windę do opuszczania trumny do grobu, nagłośnienie czy też baldachim.
                        Oferowane przez nas udogodnienia i dekoracje oferowane są bezpłatnie do każdej ceremonii pogrzebowej obslugiwanej przez naszą firmę.
                    </p>
                </div>
            </div>
            <div className="container">
                <div className="galeria__section__content">
                  <Lightbox photos={data.gallery_funeral.edges} />
                </div>
            </div>
        </div>
    </HomeLayout>
  </>
)
export const pageQuery = graphql`
    query {
        logo: file(relativePath: { eq: "logo_poziome.png" }) {
            childImageSharp {
                fluid(maxWidth: 300) {
                    ...GatsbyImageSharpFluid
                }
            }
        },
        gallery_dom: allFile(filter: {sourceInstanceName: { eq: "gallery-dom" }}) {
            edges {
              node {
                publicURL
                childImageSharp {
                  fluid(maxWidth:800,  quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
        },
        gallery_cars: allFile(filter: {sourceInstanceName: { eq: "gallery-cars" }}) {
            edges {
              node {
                publicURL
                childImageSharp {
                  fluid(maxWidth:800,  quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
        },
        gallery_funeral: allFile(filter: {sourceInstanceName: { eq: "gallery-funeral" }}) {
            edges {
              node {
                publicURL
                childImageSharp {
                  fluid(maxWidth:800,  quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
        }
    }
`

export default GaleriaPage
