import React, { Component } from "react"
import Img from "gatsby-image"
// import {
//   PopupboxManager,
//   PopupboxContainer
// } from 'react-popupbox';
import "react-popupbox/dist/react-popupbox.css"

export default class Lightbox extends Component {
  
  openPopupbox(image) {
    // const content = (
    //   <Img className="" fluid={image.node.childImageSharp.fluid}/>
    // )
    
    // PopupboxManager.open({
    //   content,
    //   config: {
    //     titleBar: {
    //       enable: false
    //     },
    //     fadeIn: true,
    //     fadeInSpeed: 500
    //   }
    // })
  }

  render() {
    return (
        <>
        {this.props.photos.map(image => (
            <div className="galeria__item_wrapper" key={image.node.childImageSharp.fluid.src}>
              <Img className="galeria__item" fluid={image.node.childImageSharp.fluid}/>
            </div>
        ))}
        </>
    ) 
  }
}